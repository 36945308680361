.aktuelles {

	position: relative;
	color: var(--color-white);

	background-color: #000;
	background-attachment: fixed;
	background-image: url(RESOURCE/img/sylt_banner_gras.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	.overlay {
		background-color: rgba(0, 0, 0, 0.5);
	}


	.heading1 {
		color: var(--color-white);
	}

	p,
	div {
		color: var(--color-white);
	}

}