.searchpanel-v1 {

	.sidebar-search {
		background-color: var(--color-white);
		padding: 5px;
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.search-bar {
		padding: 10px 15px;
		background-color: var(--widget-header-bg-color);

		.title {
			font-size: var(--h4-font-size);
			font-weight: 500;
			color: var(--color-white);
		}

	}

	.label-li {
		li {
			margin: 3px 0;
			padding: 0;
			font-size: var(--font-size-md);

			.checkbox {
				padding: 0 0 0 5px;
			}
		}
	}

	.search-bar-content {
		padding: 15px;

		.filter-icon {
			position: relative;

			select {
				-webkit-appearance: none;
			}

			.fa {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 1rem;
				opacity: 0.5;
				pointer-events: none;
			}
		}

	}

	.sidebar-search {
		@media (min-width:993px) {
			display: block !important;
		}

		.search-m-form {
			@media (min-width:993px) {
				display: none;
			}



			@media (max-width:992px) {
				background-color: var(--darkblue);
				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}
	}

	#searchpanel {
		.container {
			width: auto;
		}

		@media (min-width:992px) {
			position: relative;
			display: block !important;
			overflow: visible;

			.close {
				display: none;
			}

			&.modal {
				z-index: 3 !important;
			}

			&.fade {
				opacity: 1;
			}

			.modal-dialog {
				width: auto;
				-webkit-transform: translateY(0%);
				transform: translateY(0%);
				margin: 0;
				pointer-events: inherit;
			}
		}


		@media (max-width:992px) {
			.search-bar {
				border: 1px solid #eee;
				border-top: none;
			}

			&.modal.fade {
				.modal-dialog {
					-webkit-transform: translateX(-100%);
					transform: translateX(-100%);
					position: fixed !important;
				}

				&.show {
					.modal-dialog {
						-webkit-transform: translateX(0);
						transform: translateX(0);
						pointer-events: inherit;
					}
				}
			}
		}

		.modal-dialog {
			@media (max-width:992px) {
				margin: 0;
				padding: 50px 15px 15px;
				z-index: 20001;
				max-width: 650px;
				width: 100%;
				background-color: var(--color-grey-light);
				overflow-y: scroll;
				height: 100vh;

				>.close {
					padding: 0 8px 1px;
					position: absolute;
					top: 6px;
					right: 16px;
					z-index: 50;
					opacity: 1;
					font-size: 36px;
				}
			}
		}
	}

}