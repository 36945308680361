.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.second-col {
			@media (min-width:1200px) {
				padding-right: 60px;
			}
		}

		.third-col {
			@media (max-width:1200px) {
				padding-top: 35px;
			}
		}

		.headline {
			font-size: 22px;
			color: #c5c5c5;
			text-transform: uppercase;
			line-height: 24px;
			padding-bottom: 20px;
		}

		.list-unstyled {
			.fa {
				padding-right: 30px;
				color: var(--color-primary)
			}
		}


		.logo {
			width: 250px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 20px;
		}

		.openings {
			font-style: italic;

			div {
				padding-top: 10px;
			}
		}

		.facebook {
			margin-top: 35px;
			text-align: right;

			.fa {
				height: 45px;
				width: 45px;
				background: #686868;
				text-align: center;
				border-radius: 50%;
				line-height: 45px;
				color: #fff;

			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
		border-top: 1px solid #fff;
	}

}