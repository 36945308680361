.header-image {

	width: 100%;

	.header-inner {
		display: none;
	}

	@media (min-width:993px) {
		height: 425px;

		background-image: url(RESOURCE/img/roteskliff_1920_540.jpg);
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;

	}

	@media (max-width:992px) {
		.header-inner {
			display: block;

		}
	}

	img {
		width: 100%;
		height: auto;
		margin-top: -2px;
	}

}