.unit-book-now-v1 {
	@media(min-width:992px) {
		background-color: var(--color-white);
		padding: 20px 15px;
		position: -webkit-sticky;
		position: sticky;
		z-index: 12;
		top: 180px;
		border: 1px solid var(--color-primary);
	}
}

.sylt-go-section {
	border: 1px solid var(--color-primary);
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 15px;
	color: var(--font-color-main);

	@media (max-width: 1199px) {
		margin-bottom: var(--unit-section-mb) !important;
	}


	img {
		min-width: 65px;
		width: 65px;
		margin: 0 15px 15px 0;
		position: absolute;
		left: 15px;
		top: 15px;
	}

	.content {
		padding: 0 0 0 80px;

		h4 {
			font-size: 16px;
			font-weight: 600;
		}

		p {
			font-size: 13px;
			margin: 0;

			a {
				margin: 10px 0 0;
				display: inline-block;
				font-size: 14px;
				font-weight: 500;

			}
		}


	}
}