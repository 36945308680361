.reiseruecktritt {
	margin-top: var(--page-margin-top);

	h1 {
		font-size: 42px;
	}

	h3 {
		font-size: 32px;
	}

}