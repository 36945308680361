.faq-v2 {
	margin-top: var(--page-margin-top);


	.trennlinie {
		width: 100%;
		border-bottom: 1px solid #000;
	}

	.card {
		background-color: transparent;
		border-bottom: 1px solid var(--color-grey-normal);
		border-top: none;
		border-right: none;
		border-left: none;

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 7px 5px;

			h5 {
				span {
					font-size: 18px;
					font-weight: normal;
					padding-left: 10px;
					text-transform: none;

					&:hover {
						color: var(--color-secondary);
					}
				}
			}

		}

		.card-body {
			padding: 0px;
			padding-bottom: 10px;
		}

		.fas {
			color: var(--color-primary);
			position: absolute;
			left: 0px;
			font-size: 20px;
			top: 20px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}