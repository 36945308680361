.index-contact {
	background-color: var(--color-white);

	.next-col {
		@media (max-width: 767px) {
			padding-top: 30px;
		}
	}


	a {
		color: var(--font-color-main);
		border-bottom: 2px solid #d4d4d4;

		&:hover {
			border-color: #000;
			outline: 0;
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			-ms-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
		}



	}

	.title {
		color: var(--color-grey);

	}


	.icon {

		margin-bottom: 30px;

		.fa {
			font-size: 24px;
			background-color: var(--color-primary);
			width: 70px;
			height: 70px;
			border-radius: 50%;
			line-height: 70px;
			text-align: center;
			color: var(--color-white);
		}

	}


}