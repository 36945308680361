.icon-sheet {


	.icon-block {
		margin-bottom: 30px;
	}

	img {
		margin-bottom: 30px;
	}

	p {
		line-height: 18px;
	}

	a {
		font-family: 'Ubuntu', sans-serif;
		font-weight: 600;
		text-decoration: none;
		color: var(--font-color-main);

		&:hover {
			text-decoration: none;
		}
	}
}