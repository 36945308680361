.wellcome {

	position: relative;

	background-color: #000;
	background-attachment: fixed;
	background-image: url(RESOURCE/img/banner_background.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	p,
	a {
		color: var(--color-white);
	}

	p {
		line-height: 40px;
	}

	.heading1 {
		color: var(--color-white);
	}

	.wellcome-title {
		font-size: 25px;
		font-style: italic;
		color: var(--color-white);
		padding-bottom: 15px;
	}

	.wellcome-subtitle {
		font-size: 20px;
		font-style: italic;
		color: var(--color-white);
		padding-bottom: 15px;
	}
}