.eigentuemer-service {

	margin-top: var(--page-margin-top);

	h5 {
		font-size: 20px;
		padding-bottom: 25px;
	}

	.container {
		max-width: 1100px;
	}

	a {
		color: var(--font-color-main);
	}

}